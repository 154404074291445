import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { supportedLanguages } from '../../../../util/translations';
import { manageDisableScrolling } from '../../../../ducks/ui.duck';
import { InlineTextButton, LanguageModal, IconFlag } from '../../../../components';
import defaultConfig from '../../../../config/configDefault';
import classNames from 'classnames';

import css from './BlockLanguage.module.css';

const LanguageButton = props => {
  const { onOpenLanguageModal } = props;
  const currentLanguage = supportedLanguages.find(
    lang => lang.code === defaultConfig.localization.locale
  );
  return (
    <InlineTextButton className={css.languageButton} type="button" onClick={onOpenLanguageModal}>
      <span className={css.languageButtonIconContainer}>
        <IconFlag className={css.languageButtonIcon} code={currentLanguage.code} />
      </span>
      <p className={css.languageButtonText}>{currentLanguage.language}</p>
    </InlineTextButton>
  );
};

const BlockLanguage = props => {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);

  const { className, rootClassName, onManageDisableScrolling } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <LanguageButton onOpenLanguageModal={() => setLanguageModalOpen(true)} />
      <LanguageModal
        id="Footer.languageModal"
        isOpen={isLanguageModalOpen}
        onCloseModal={() => setLanguageModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </div>
  );
};

BlockLanguage.defaultProps = {
  className: null,
  rootClassName: null,
};

BlockLanguage.propTypes = {
  className: string,
  rootClassName: string,
  onManageDisableScrolling: func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(BlockLanguage);
