/**
 * Function to retrieve localized sections based on the current locale, and fall back to default ('-en') if no localization is available.
 * It includes sections with undefined sectionName as well.
 *
 * @param {Array} sections - Array of section objects, each containing a `sectionName` property which may include localization suffixes like `-en`, `-es`, or `-de`.
 * @param {string} locale - The current locale (e.g., 'en', 'es', 'de').
 *
 * @return {Array} - Array of sections, first localized sections (if any), then non-localized sections, and fallback sections if no localization is found.
 */
export const getLocalizedSections = (sections, locale) => {
  // Step 1: Get localized sections for the current locale
  const localizedSections = sections.filter(section => section.sectionName?.endsWith(`-${locale}`));

  // Step 2: Get non-localized sections (those without any suffix)
  const nonLocalizedSections = sections.filter(
    section => !section.sectionName?.match(/-(es|en|de)$/)
  );

  // Step 3: Get the fallback sections (default -en)
  const fallbackSections = sections.filter(section => section.sectionName?.endsWith('-en'));

  // Step 4: Combine all sections
  const allSections = [];

  // First, add the localized sections (if any) to the result
  localizedSections.forEach(localizedSection => {
    // Ensure sectionName is defined before using it
    const sectionNameBase = localizedSection.sectionName?.replace(`-${locale}`, '');
    if (
      sectionNameBase &&
      !allSections.some(section => section.sectionName?.startsWith(sectionNameBase))
    ) {
      allSections.push(localizedSection);
    }
  });

  // Next, add non-localized sections (only if not already added)
  nonLocalizedSections.forEach(nonLocalizedSection => {
    // Ensure sectionName is defined before using it
    const sectionNameBase = nonLocalizedSection.sectionName?.replace(/-(es|en|de)$/, '');
    if (
      sectionNameBase &&
      !allSections.some(section => section.sectionName?.startsWith(sectionNameBase))
    ) {
      allSections.push(nonLocalizedSection);
    }
  });

  // Finally, add fallback sections (only if not already added)
  fallbackSections.forEach(fallbackSection => {
    // Ensure sectionName is defined before using it
    const sectionNameBase = fallbackSection.sectionName?.replace(/-(es|en|de)$/, '');
    if (
      sectionNameBase &&
      !allSections.some(section => section.sectionName?.startsWith(sectionNameBase))
    ) {
      allSections.push(fallbackSection);
    }
  });

  // Include sections with undefined sectionName as well
  const undefinedSection = sections.filter(section => section.sectionName === undefined);
  allSections.push(...undefinedSection);

  return allSections;
};
