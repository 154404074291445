/**
 * Function to retrieve localized blocks based on the current locale, and fall back to default ('-en') if no localization is available.
 * It includes blocks with undefined blockId or an empty string as well.
 *
 * @param {Array} blocks - Array of block objects, each containing a `blockId` property which may include localization suffixes like `-en`, `-es`, or `-de`.
 * @param {string} locale - The current locale (e.g., 'en', 'es', 'de').
 *
 * @return {Array} - Array of blocks, first localized blocks (if any), then non-localized blocks, and fallback blocks if no localization is found.
 */
export const getLocalizedBlocks = (blocks, locale) => {
  // Step 1: Get localized blocks for the current locale
  const localizedBlocks = blocks.filter(block => block.blockId?.endsWith(`-${locale}`));

  // Step 2: Get non-localized blocks (those without any suffix)
  const nonLocalizedBlocks = blocks.filter(block => !block.blockId?.match(/-(es|en|de)$/));

  // Step 3: Get the fallback blocks (default -en)
  const fallbackBlocks = blocks.filter(block => block.blockId?.endsWith('-en'));

  // Step 4: Combine all blocks
  const allBlocks = [];

  // First, add the localized blocks (if any) to the result
  localizedBlocks.forEach(localizedBlock => {
    // Ensure blockId is defined before using it
    const blockIdBase = localizedBlock.blockId?.replace(`-${locale}`, '');
    if (blockIdBase && !allBlocks.some(block => block.blockId?.startsWith(blockIdBase))) {
      allBlocks.push(localizedBlock);
    }
  });

  // Next, add non-localized blocks (only if not already added)
  nonLocalizedBlocks.forEach(nonLocalizedBlock => {
    // Ensure blockId is defined before using it
    const blockIdBase = nonLocalizedBlock.blockId?.replace(/-(es|en|de)$/, '');
    if (blockIdBase && !allBlocks.some(block => block.blockId?.startsWith(blockIdBase))) {
      allBlocks.push(nonLocalizedBlock);
    }
  });

  // Finally, add fallback blocks (only if not already added)
  fallbackBlocks.forEach(fallbackBlock => {
    // Ensure blockId is defined before using it
    const blockIdBase = fallbackBlock.blockId?.replace(/-(es|en|de)$/, '');
    if (blockIdBase && !allBlocks.some(block => block.blockId?.startsWith(blockIdBase))) {
      allBlocks.push(fallbackBlock);
    }
  });

  // Include blocks with undefined blockId or empty string as well
  const undefinedOrEmptyBlock = blocks.filter(
    block => block.blockId === undefined || block.blockId === ''
  );
  allBlocks.push(...undefinedOrEmptyBlock);

  return allBlocks;
};
